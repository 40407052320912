.root {
    flex-grow: 1;
}

.grid_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.grid_cell {
    display: flex;
    justify-content: center;
    padding: 8px;
    color: #123150;
    transition: all .3s ease-in;
}

.grid_cell .card_icons_title {
    position: relative;
}

.grid_cell .card_icons_title > :nth-child(1),
.grid_cell .card_icons_title > :nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    transition: all .3s ease-in;
}

.card1:hover .title1 span,
.card2:hover .title2 span {
    color: #E22B36;
}

.card1:hover .card_icons_title > :nth-child(1) {
    opacity: 0;
}

.card1:hover .card_icons_title > :nth-child(2) {
    opacity: 1;
}

.card2:hover .card_icons_title > :nth-child(1) {
    opacity: 0;
}

.card2:hover .card_icons_title > :nth-child(2) {
    opacity: 1;
}

.card_icons1 {
    height: 115px;
}

.card_icons2 {
    height: 65px;
}

.card_icons1,
.card_icons2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 10px;
}

.card_icons1 > span > :nth-child(2),
.card_icons2 > span > :nth-child(2) {
    opacity: 0;
    transition: all .3s ease-in;
}

.card_plus {
    display: flex;
    background-color: #123150;
    border-radius: 50%;
    padding: 10px;
    transition: all .3s ease-in;
    box-shadow: inset -3px -3px 4px rgba(124, 156, 188, 0.57), inset 3px 3px 4px rgba(0, 0, 0, 0.76);
}

.card_plus svg {
    fill: #FFFFFF;
}

@keyframes rotate {
   0% {
       transform: rotate(0);
   }

   100% {
       transform: rotate(-90deg);
   }
}

.card_plus:hover .grid_cell .card_icons_title > :nth-child(2) {
    background-color: black;
    opacity: 0;
}

.card_plus:hover .plus {
    fill: #E22B36;
    transform: rotate(-90deg);
    animation: rotate .3s forwards;
    
}

.card_plus:hover .plus:after {
    background: #E22B36;
}

.card_plus:hover .plus:before {
    background: #E22B36;
}

.card_plus_disabled {
    display: flex;
    background-color: #123150;
    border-radius: 50%;
    padding: 10px;
    box-shadow: inset -3px -3px 4px rgba(124, 156, 188, 0.57), inset 3px 3px 4px rgba(0, 0, 0, 0.76);
}

.plus {
  position: relative;
  width:30px;
  height:30px;
}

.plus:before,
.plus:after {
  content: "";
  position:absolute;
  background:#fff;
}

.plus:before {
  left:50%;
  top:4px;
  bottom:4px;
  width:4px;
  transform:translateX(-50%);
}

.plus:after {
  top:50%;
  left:4px;
  right:4px;
  height:4px;
  transform:translateY(-50%);
}

.card_plus_disabled > .plus {
    opacity: 0.6;
    fill: #858584;
}

.card_plus_disabled svg {
    fill: #FFFFFF;
    background: #FFFFFF
}

.title1 span,
.title2 span {
    font-weight: 600;
    text-align: start;
    color: #123150;
    line-height: normal;
    padding: 30px 10px;
    transition: all .22s ease-in;
}

.title1 span {
    font-size: 24px;
}

.title2 span {
    font-size: 16px;
}

.card1.MuiPaper-rounded {
    width: 280px;
    height: 280px;
    border-radius: 35px;
    box-shadow: -10px -10px 20px rgba(198, 219, 238, 0.42), 10px 10px 20px #C0C0C0;
}

.card2.MuiPaper-rounded {
    width: 200px;
    height: 200px;
    border-radius: 35px;
    box-shadow: -10px -10px 20px rgba(198, 219, 238, 0.42), 10px 10px 20px #C0C0C0;
}

.MuiPaper-root.card_clasifier {
    margin: 30px;
    border-radius: 35px;
    box-shadow: -10px -10px 20px rgba(198, 219, 238, 0.42), 10px 10px 20px #C0C0C0;
}

.MuiPaper-root.card_clasifier > div {
    padding-bottom: 0;
}

.card_clasifier_actions_grid_shevron{
    position: relative;
    height: 50px;
    padding-left: 10px;
}

.card_clasifier_actions_grid_shevron:before,
.card_clasifier_actions_grid_shevron:after{
    position: absolute;
    content: "";
    border: 17px solid transparent;
}

.card_clasifier_actions_grid_shevron:before{
    top: 4px;
    border-top-color: #123150;
    border-radius: 25%;
}

.card_clasifier_actions_grid_shevron:after{
    top: -2px;
    border-top-color: #fff;
}

.MuiPaper-root.card_clasifier > div > p {
    padding-bottom: 0;
}

.MuiCardActions-root.card_clasifier_actions_block {
    display: flex;
    justify-content: center;
}

.MuiGrid-root.card_clasifier_actions_grid {
    flex-wrap: wrap;
    padding-bottom: 20px;
}

.MuiGrid-root.card_clasifier_actions_grid_cell.MuiGrid-item {
    padding: 0 20px 20px 20px;
    text-align: center;
}

.card_clasifier_title.MuiTypography-body1 {
    font-weight: 700;
    color: #123150;
    font-size: 36px;
    padding: 30px;
}

.card_clasifier_actions_grid_titles {
    text-align: start;
    color: #123150;
    padding: 10px 40px;
    font-size: 24px;
    font-weight: 700;
}

.card_clasifier_actions_grid_list {
    text-align: start;
    padding: 0 60px;
}

.card_clasifier_actions_anchor {
    text-decoration: none;
    font-weight: 500;
    color: #123150;
    cursor: pointer;
}

.card_clasifier_actions_anchor > p {
    font-size: 18px;
    transition: all .3s ease-in;
}

.card_clasifier_actions_anchor > p:hover {
    color: #E22B36;
}

.link {
    text-decoration: none;
    cursor: pointer;
}

.active_link .MuiTypography-root {
    font-weight: bold;
    color: #123150;
    letter-spacing: 1;
}

.active_link .MuiSvgIcon-root {
    color: #123150;
}
