.rc_root,
.rc_shortcut_cal_root {
  /* General Theme */
  --rc-color-transparent: rgba(0, 0, 0, 0);
  --rc-color-text-dark: #353535;
  --rc-color-text-inactive: #c9c9ca;
  --rc-color-text-light: #fff;
  --rc-color-bg-light: #fff;
  --rc-color-border: #f3f3f3;
  --rc-color-text-hover: rgb(248, 249, 250);
  /* General Theme Main Colors Parts */
  --rc-hsl-primary-hue: 208deg;
  --rc-hsl-primary-saturation: 77%;
  --rc-hsl-primary-light: 47%;
  --rc-hsl-accent-hue: 0deg;
  --rc-hsl-accent-saturation: 77%;
  --rc-hsl-accent-light: 47%;
  /* General Theme Main Colors */
  --rc-color-primary: hsl(var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light));

  --rc-color-primary-light: hsla(
    var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light) / 40%
  );

  --rc-color-primary-lighter: hsla(
    var(--rc-hsl-primary-hue) var(--rc-hsl-primary-saturation) var(--rc-hsl-primary-light) / 8%
  );

  --rc-color-accent: hsl(var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light));

  --rc-color-accent-light: hsla(
    var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light) / 40%
  );

  --rc-color-accent-lighter: hsla(
    var(--rc-hsl-accent-hue) var(--rc-hsl-accent-saturation) var(--rc-hsl-accent-light) / 8%
  );

  /* Context Specific */
  --rc-color-border-root: var(--rc-color-border);
  --rc-color-bg-text-hover-header-button: var(--rc-color-text-hover);
  --rc-color-text-today: var(--rc-color-primary);
  --rc-color-border-weekdays: var(--rc-color-border);
  --rc-color-text-weekday-label: var(--rc-color-text-inactive);
  --rc-color-text-weekend-label: var(--rc-color-accent-light);
  --rc-color-text-dom-inactive: var(--rc-color-text-inactive);
  --rc-color-text-dom-active: var(--rc-color-text-dark);
  --rc-color-text-dom-weekend-active: var(--rc-color-accent);
  --rc-color-text-dom-weekend-inactive: var(--rc-color-accent-light);
  --rc-color-bg-dom-in-range-active: var(--rc-color-primary-lighter);
  --rc-color-bg-dom-in-range-inactive: var(--rc-color-primary-lighter);
  --rc-color-bg-dom-in-range-weekend-active: var(--rc-color-accent-lighter);
  --rc-color-bg-dom-range-ep-active: var(--rc-color-primary);
  --rc-color-bg-dom-range-ep-inactive: var(--rc-color-primary-lighter);
  --rc-color-bg-dom-range-ep-weekend-active: var(--rc-color-accent);
  --rc-color-bg-dom-range-ep-weekend-inactive: var(--rc-color-accent-lighter);
  --rc-color-text-dom-range-ep-weekend-inactive: var(--rc-color-accent-light);
  --rc-color-text-dom-range-ep-active: var(--rc-color-text-light);
  --rc-color-text-dom-range-ep-inactive: var(--rc-color-primary-light);
  --rc-color-bg-dom-selected: var(--rc-color-primary);
  --rc-color-bg-dom-weekend-selected: var(--rc-color-accent);
  --rc-color-text-dom-selected: var(--rc-color-text-light);
  --rc-color-text-dom-weekend-selected: var(--rc-color-text-light);
  --rc-color-text-disabled: var(--rc-color-text-inactive);
  --rc-color-text-disabled-weekend: var(--rc-color-accent-light);
  --rc-color-bg-disabled: var(--rc-color-bg-light);
  --rc-color-bg-disabled-cross: var(--rc-color-text-inactive);
  --rc-color-bg-disabled-weekend-cross: var(--rc-color-accent-light);
}

.rc_shortcut_cal_root.rc_dark,
.rc_root.rc_dark {
  /* General Theme */
  --rc-color-transparent: rgba(0, 0, 0, 0);
  --rc-color-text-dark: #fff;
  --rc-color-text-inactive: #727273;
  --rc-color-text-light: #353535;
  --rc-color-bg-light: #353535;
  --rc-color-border: #4e4d4d;
  --rc-color-text-hover: #403e3e;
  /* General Theme Main Colors */
  --rc-color-primary: hsl(216deg 40% 86%);
  --rc-color-primary-light: hsl(230deg 8% 46%);
  --rc-color-primary-lighter: hsl(216deg 40% 86% / 18%);
  /* --rc-color-primary-lighter: hsl(218deg 7% 30%); */
  --rc-color-accent: hsl(0deg 40% 86%);
  --rc-color-accent-light: hsl(0deg 8% 46%);
  --rc-color-accent-lighter: hsl(0deg 40% 86% / 18%);
  --rc-color-shorcut-button: #ced9ea;
  --rc-color-shorcut-button-text: #353535;
  --rc-color-shorcut-button-hover: #b5c3db;
}

.rc_root {
  background-color: var(--rc-color-bg-light);
  border: 1px solid var(--rc-color-border-root);
  font-size: 1rem;
  border-radius: 8px;
  position: relative;
}

.rc {
  position: relative;
}

.rc_root.rc_dual .rc + .rc {
  margin-left: 12px;
}

.rc_root.rc_dual .rc + .rc::before {
  background-color: var(--rc-color-border);
  left: -12px;
  content: '';
  width: 12px;
  height: 1px;
  top: 25%;
  position: absolute;
}

.rc_root * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: var(--rc-color-transparent);
  font-family: inherit;
}

.rc_root button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
}

.rc_root button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/** rc_header **/

.rc_header_nav {
  border-radius: 6px;
  cursor: pointer;
}

.rc_header_label {
  border-radius: 6px;
  cursor: pointer;
}

.rc_header_label div + div {
  margin-left: 2.5%;
}

.rc_header span {
  font-size: 0.85em;
  color: var(--rc-color-text-dark);
}

.rc_header button:hover {
  background-color: var(--rc-color-bg-text-hover-header-button);
}

.rc_header button:active {
  transform: translateY(1px);
}

/** rc_body **/

.rc_body {
  width: 100%;
}

/** rc_shortcut_root **/

.rc_shortcut_cal_root {
  background-color: var(--rc-color-bg-light);
  border: 1px solid var(--rc-color-border-root);
  margin-bottom: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.rc_shortcut_cal_root .rc_root {
  border: none;
  margin: 0;
  border-radius: 0;
}

/** rc_shortcut_view **/

.rc_shortcuts_view {
  background-color: var(--rc-color-bg-light);
}

.rc_shortcut_cal_root.rc_dir-left .rc_shortcuts_view {
  border-right: 1px solid var(--rc-color-border);
}

.rc_shortcut_cal_root.rc_dir-right .rc_shortcuts_view {
  border-left: 1px solid var(--rc-color-border);
}

.rc_shortcut_cal_root.rc_dir-bottom .rc_shortcuts_view {
  border-top: 1px solid var(--rc-color-border);
}

/** rc_body-months **/

.rc_body-months .rc_body-cell button {
  font-size: 0.85em;
  color: var(--rc-color-text-dom-active);
  cursor: pointer;
}

.rc_body-months .rc_body-cell button:hover {
  background-color: var(--rc-color-bg-text-hover-header-button);
}

.rc_body-months .rc_body-cell.rc_this_month {
  border-radius: 6px;
  color: var(--rc-color-text-today);
}

/** rc_body-years **/

.rc_body-years .rc_body-cell button {
  font-size: 0.85em;
  color: var(--rc-color-text-dom-active);
  cursor: pointer;
}

.rc_body-years .rc_body-cell button:hover {
  background-color: var(--rc-color-bg-text-hover-header-button);
}

.rc_body-years .rc_body-cell.rc_this_year {
  border-radius: 6px;
  color: var(--rc-color-text-today);
}

/** Days of Month View - Weekdays Header Row **/

.rc_body-weekdays {
  border-bottom: 1px solid var(--rc-color-border-weekdays);
}

.rc_body-weekdays_cell {
  color: var(--rc-color-text-weekday-label);
  font-size: 0.83em;
  text-transform: uppercase;
}

.rc_body-weekdays_cell.rc_wknd {
  color: var(--rc-color-text-weekend-label);
}

/** rc_body-days-of-month **/

.rc_body-days-of-month .rc_body-cell {
  cursor: pointer;
}

.rc_body-days-of-month .rc_body-cell .rc_body-cell_value {
  cursor: pointer;
  border-radius: 2px;
}

/* No Pad Mode */
.rc_no_range_padding .rc_body-days-of-month .rc_body-cell .rc_body-cell_value {
  border-radius: 0;
}

.rc_body-days-of-month .rc_body-cell .rc_body-cell_value:hover {
  background-color: var(--rc-color-bg-text-hover-header-button);
}

.rc_body-days-of-month .rc_body-cell button {
  font-size: 0.83em;
  color: var(--rc-color-text-dom-inactive);
  cursor: pointer;
}

.rc_body-days-of-month .rc_body-cell.rc_active button {
  color: var(--rc-color-text-dom-active);
}

.rc_body-days-of-month .rc_body-cell.rc_wknd button {
  color: var(--rc-color-text-dom-weekend-active);
}

.rc_body-days-of-month .rc_body-cell.rc_wknd:not(.rc_active) button {
  color: var(--rc-color-text-dom-weekend-inactive);
}

/* in range bg colors */
.rc_body-days-of-month .rc_body-cell.rc_in_range .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-in-range-active);
  color: var(--rc-color-text-dark);
}

.rc_no_range_padding .rc_body-days-of-month .rc_body-cell.rc_range_end .rc_body-cell_value {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.rc_no_range_padding .rc_body-days-of-month .rc_body-cell.rc_range_start .rc_body-cell_value {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.rc_body-days-of-month .rc_body-cell.rc_in_range.rc_wknd .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-in-range-weekend-active);
  color: var(--rc-color-text-dark);
}

/* range endpoints color */
.rc_body-days-of-month .rc_body-cell.rc_range_start button,
.rc_body-days-of-month .rc_body-cell.rc_range_end button {
  color: var(--rc-color-text-dom-range-ep-active);
}

.rc_body-days-of-month .rc_body-cell.rc_wknd.rc_range_start button,
.rc_body-days-of-month .rc_body-cell.rc_wknd.rc_range_end button {
  color: var(--rc-color-text-dom-range-ep-active);
}

/* range non weekend endoints */
.rc_body-days-of-month .rc_body-cell.rc_range_start .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_range_end .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-range-ep-active);
}

/*range weekend endoints */
.rc_body-days-of-month .rc_body-cell.rc_range_start.rc_wknd .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_range_end.rc_wknd .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-range-ep-weekend-active);
}

.rc_body-days-of-month .rc_body-cell.rc_range_start.rc_wknd.rc_active button,
.rc_body-days-of-month .rc_body-cell.rc_range_end.rc_wknd.rc_active button {
  border-bottom-color: var(--rc-color-bg-dom-range-ep-weekend-active);
}

/* Selected */

.rc_body-days-of-month .rc_body-cell.rc_today:not(.rc_disabled) button,
.rc_body-days-of-month .rc_body-cell.rc_highlight:not(.rc_disabled) button {
  border-bottom: 1px solid currentColor;
}

.rc_body-days-of-month .rc_body-cell.rc_selected .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-selected);
}

.rc_body-days-of-month .rc_body-cell.rc_selected button {
  color: var(--rc-color-text-dom-selected);
}

.rc_body-days-of-month .rc_body-cell.rc_selected.rc_wknd .rc_body-cell_value {
  background-color: var(--rc-color-bg-dom-weekend-selected);
}

.rc_body-days-of-month .rc_body-cell.rc_selected.rc_wknd button {
  color: var(--rc-color-text-dom-weekend-selected);
}

/* Disabled */

.rc_body-days-of-month .rc_body-cell.rc_disabled {
  position: relative;
}

.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_in_range.rc_wknd .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_in_range .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd .rc_body-cell_value,
.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value {
  background-color: var(--rc-color-bg-disabled);
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value button {
  color: var(--rc-color-text-disabled);
  cursor: none;
  pointer-events: none;
}

.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd .rc_body-cell_value button {
  color: var(--rc-color-text-disabled-weekend);
}

.rc_body-days-of-month .rc_body-cell.rc_disabled .rc_body-cell_value button::before {
  content: '';
  position: absolute;
  top: 15%;
  left: 50%;
  transform: rotate(45deg);
  font-weight: 300;
  display: block;
  height: 70%;
  background-color: var(--rc-color-bg-disabled-cross);
  width: 1px;
}

.rc_body-days-of-month .rc_body-cell.rc_disabled.rc_wknd button::before {
  background-color: var(--rc-color-bg-disabled-weekend-cross);
}

.rc_body-days-of-month .rc_body-cell.rc_wknd button::before {
  background-color: var(--rc-color-bg-disabled-weekend-cross);
}
